import React from 'react'
import { Parallax } from 'react-parallax'
import {
  CFImage,
  CFView,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  about,
  aboutParallax,
  aboutGladwin,
  aboutWhatcom,
  mobileAboutGladwin,
  mobileAboutWhatcom,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let AboutMobileImage = null
        let about = null
        if (locationId === 'CIRDYTG9isJMeE5SO5ff') {
          about = aboutWhatcom
          AboutMobileImage = mobileAboutWhatcom
        } else {
          about = aboutGladwin
          AboutMobileImage = mobileAboutGladwin
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView column center>
                <CFImage
                  src={AboutMobileImage}
                  w="100%"
                  alt="aboutImageMobile"
                />
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView row justifyStart alignCenter m="0 auto" pt="30px">
                <CFImage
                  src={about}
                  w="1200px"
                  alt="Port Coquitlam Sushi Nara Intro"
                />
              </CFView>
              <CFView maxWidth="1100px" m="0 auto">
                <Parallax
                  bgImage={aboutParallax}
                  bgImageAlt="Port Coquitlam Sushi Nara aboutParallax"
                  strength={200}
                >
                  <div style={{ height: '400px' }} />
                </Parallax>
              </CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
