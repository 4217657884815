import React from 'react'
import { css } from 'emotion'
import {
  CFView,
  CFImage,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  doordash,
  hero,
  heroText,
  logo,
  mobileHero,
  orderPickupButton,
  downloadAppButton,
  mobileHeroText,
  mobileOrderButton,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          column
          justifyBetween
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
        >
          <Header />
          <CFView textCenter column justifyEnd alignCenter mb="15px">
            <CFView column center pb="15px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView column center pb="15px">
              <OrderDeliveryButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="700px"
          w="100%"
          maxWidth="1400px"
          m="0 auto"
          image={`url(${hero}) left/ cover no-repeat`}
          boxShadow="0 2px 3px rgba(0,0,0,.6)"
          zIndex={90}
        >
          <Header />
          <CFView pl="130px" column justifyStart alignStart w="100%" mv="30px">
            <CFImage src={heroText} w="300px" alt="About" />
            <CFView row center mt="50px" pulsate>
              <OrderPickupButton />
            </CFView>
            <CFView>
              <OrderDeliveryButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
