import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerBg, logo, logoTitle, mobileLogoTitle, viewMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/jwtXW2Bc6tUEEYtcnIIt/locations/MvLV4OUJjaddWhfyWwXM'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView column center w="100%">
          <CFImage
            h="80px"
            mb="15px"
            mt="10px"
            src={logo}
            alt="Sushi Nara Logo"
          />
          <LocationSelect />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView row justifyStart zIndex={98} maxWidth="1400px">
          <CFView row justifyBetween alignCenter w="100%">
            <CFView row justifyStart mv="20px" ml="130px">
              <CFImage
                h="115px"
                src={logo}
                alt="Chicko Korean Fried Chicken Logo"
                zIndex={98}
              />
              <CFSelect selector={dispatch.restaurant.getHasMultipleLocations}>
                <CFView mt="15px" zIndex={99}>
                  <LocationSelect />
                </CFView>
              </CFSelect>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
