import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { orderDeliveryButton } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let deliveryLink = ''
        let deliveryButtonImage = ''
        if (locationId === 'CIRDYTG9isJMeE5SO5ff') {
          deliveryLink =
            'https://www.doordash.com/en-CA/store/sushi-nara-whatcom-rd.-abbotsford-209183/?utm_campaign=gpa'
          deliveryButtonImage = orderDeliveryButton
        } else {
          deliveryLink =
            'https://order.online/store/sushi-nara--gladwin-abbotsford-24469375/?delivery=true&hideModal=true'

          deliveryButtonImage = null
        }
        return (
          <CFView>
            <MobileScreen>
              <a href={deliveryLink}>
                <CFView hover>
                  <CFImage src={deliveryButtonImage} maxWidth="280px" />
                </CFView>
              </a>
            </MobileScreen>
            <DefaultScreen>
              <a href={deliveryLink}>
                <CFView hover>
                  <CFImage src={deliveryButtonImage} maxWidth="260px" />
                </CFView>
              </a>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
