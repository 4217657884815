import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  locationMapGladwin,
  locationMapWhatcom,
  mobileMapWhatcom,
  mobileMapGladwin,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let locationLink = ''
        let mapImageMobile = null
        let mapImage = null
        if (locationId === 'CIRDYTG9isJMeE5SO5ff') {
          locationLink = 'https://goo.gl/maps/FXgjWS7kMzpxDr769'
          mapImage = locationMapWhatcom
          mapImageMobile = mobileMapWhatcom
        } else {
          locationLink = 'https://goo.gl/maps/aUDWjGqj8KJxP7YV8'
          mapImage = locationMapGladwin
          mapImageMobile = mobileMapGladwin
        }
        return (
          <CFView>
            <MobileScreen>
              <CFLink href={locationLink}>
                <CFImage src={mapImageMobile} w="100%" alt="Sushi Nara Map" />
              </CFLink>
            </MobileScreen>
            <DefaultScreen>
              <CFLink href={locationLink}>
                <CFImage src={mapImage} w="100%" alt="Sushi Nara Map" />
              </CFLink>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
