export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/yelp.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/facebook.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/zomato.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1585671918/cafesaladedefruits/saladedefruits_instagram.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1591377852/general/cflogo_black.png'
export const heroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1621298112/nara/heroText.png'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1593156320/ogenki/mobileContactbg.jpg'
export const aboutGladwin =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1621291173/nara/about.png'
export const aboutWhatcom =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1621357538/nara/aboutWhatcom.png'
export const downloadAppButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1597536489/Sushi%20On/downloadAppButton.png'
export const doordash =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1609527857/Sushi%20On/doordashButton.png'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1621289744/nara/foodGallery.png'
export const locationMapGladwin =
  'https://res.cloudinary.com/codefusionlisa/image/upload/v1615836389/sushinara/locationMapGladwin.jpg'
export const locationMapWhatcom =
  'https://res.cloudinary.com/codefusionlisa/image/upload/v1615836389/sushinara/locationMapWhatcom.jpg'
export const locationSelectBg =
  'https://res.cloudinary.com/codefusionlisa/image/upload/v1615838839/sushinara/locationSelectBg.jpg'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1621297166/nara/logo.png'
export const logoDark =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1621363420/nara/logoDark.png'
export const mobileAboutGladwin =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1621300705/nara/mobileAbout.png'
export const mobileAboutWhatcom =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1621357539/nara/mobileAboutWhatcom.png'
export const mobileGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1621301377/nara/mobileGallery.png'
export const mobileHero =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1621362058/nara/mobileHero2.jpg'
export const mobileMapGladwin =
  'https://res.cloudinary.com/codefusionlisa/image/upload/v1615838494/sushinara/mobileMapGladwin.jpg'
export const mobileMapWhatcom =
  'https://res.cloudinary.com/codefusionlisa/image/upload/v1615838494/sushinara/mobileMapWhatcom.jpg'
export const mobilePromotion =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1621300751/nara/mobilePromotion.png'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1621289744/nara/orderButton.png'
export const orderDeliveryButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1685051870/nara/deliveryButton.png'
export const promotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1621289744/nara/promotions.png'
export const promoParallax =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1621291061/nara/promoPara.jpg'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1621297998/nara/hero.jpg'
export const homebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1601837166/Sushi%20On/darkwood.jpg'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1601837166/Sushi%20On/contactbg.jpg'
export const aboutParallax =
  'https://res.cloudinary.com/codefusionlisa/image/upload/v1615836744/sushinara/aboutParallax.jpg'
export const homebg2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1601837166/Sushi%20On/concrete.jpg'
